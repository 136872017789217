export default function Content() {
    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
                        <span className="block text-base text-left text-indigo-600 font-semibold tracking-wide uppercase">
                            APR 14TH, 2022
                        </span>
                        <span className="mt-2 block text-3xl text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            The eleven key components of financial literacy
                        </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Financial literacy is important because life is full of twists and turns.
                    </p>
                </div>
                <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                    <p>
                        Ways to Improve Your Financial Literacy
                    </p>
                    {/* <h3>Table of contents</h3>
                    <ul >
                        <li>Learn about money matters</li>
                        <li>Use financial management tools</li>
                        <li>Learn to budget</li>
                        <li>Understand credit</li>
                        <li>Create and manage a checking and savings account</li>
                        <li>Understand debt and loans</li>
                        <li>Invest in retirement</li>
                        <li>Understand the risk of identity theft</li>
                    </ul> */}

                    {/* one */}
                    <h2>1. Know Yourself</h2>
                    <p>
                        In order to change or improve your financial situation, you’ll have to have a strong sense of what your financial situation looks like.
                    </p>
                    <p>
                        The first thing to do is get really familiar with your spending habits. Especially in the age of debit cards and Venmo, most of us spend our money without actually realizing how much we’re spending and what we’re spending it on. Use your banking apps or print out your bank statement and go through it with multi-colored pens. How much are you spending a month on eating out? What about on gas or transportation? Once you know how much you’re currently spending, you can begin assessing what needs to be adjusted. You may be really surprised about what you can trim!
                    </p>
                    <p>
                        The second thing you need to do (and this can be scary) is check your credit score. Your credit score is assigned to you based on your financial history and your relationship to paying off debts. A good credit score is necessary for a lot of things in life: getting a loan, buying a car, renting an apartment, and so on. Your credit report will also include bill payments you’ve made, outstanding balances in your name, your debt-to-income ratio, etc. Knowing your credit score is crucial for knowing what kinds of financial options you currently have (e.g. if you qualify for a loan) and for knowing where you can improve your financial patterns.
                    </p>
                    <p>
                        This is a solid overview of credit scores as well as a tutorial on how to check and monitor your credit score so that you best manage your public financial records. Several credit reporting agencies or your own bank will allow you to check your score for free! Just make sure to do your research so that you aren’t being charged or negatively affected for checking your score.
                    </p>
                    {/* <blockquote>
                        <p>
                            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur neque tristique
                            pellentesque. Blandit amet, sed aenean erat arcu morbi.
                        </p>
                    </blockquote> */}

                    <figure>
                        <img
                            className="w-full rounded-lg"
                            src="https://images.unsplash.com/photo-1617704716344-8d987ac681a4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                            alt=""
                            width={1310}
                            height={873}
                        />
                        {/* <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> */}
                    </figure>

                    {/* two */}
                    <h2>2. Budget</h2>
                    <p>
                        If you want to be savvy with money you absolutely must, must, must (did we say must?) make a budget. What exactly IS a budget? A budget is an estimated amount of income and expenses for a given amount of time. So, for example, a monthly budget is an organized list of all incoming and outgoing money for the duration of that month.
                    </p>

                    {/* three */}
                    <h2>3. Keys to budgeting</h2>
                    <p>Identify fixed and variable expenses</p>
                    <p>Fixed expenses are monthly expenses that stay the same every month: rent, your car payment, most bills, your student loan payment, etc. (Side note: you could be eligible for student loan forgiveness—read on!)</p>
                    <p>Variable expenses are monthly expenses that will fluctuate. For example, groceries, household supplies, gas, etc.</p>
                    <p>Track your spending for 3 months</p>
                    <p>Since so many expenses in life are variable, it’s important to study your spending habits over a longer period of time, ideally for about 3 months (you can also look back over 3 or more months of bank statements). Over this time, determine the average amount you spend per month on variable expenses, rounding up to account for months that may be a tad bit higher.</p>
                    <p>Once you’ve determined an amount for your average variable expenses, add this number to your fixed expenses and voila! You have your total monthly expenses.</p>
                    <p>Determine your income</p>
                    <p>If you’ve got a fixed income, this part is easy-peasy. If you don’t (for example, if you’re an independent contractor who picks up various gigs or if you work on primarily tips), you’ll want to do the same thing you did for variable expenses and track your income over a period of time to determine an average. When in doubt, to play it safe, you can always round your average monthly income number down, and anything above that number is a surplus.</p>
                    <p>Set your budget</p>
                    <p>Once you have both your expenses and your income determined, you can subtract your expenses from your income. With the number you have left, deduct a portion to set aside in savings (more on this in just a moment).</p>
                    <p>The amount of money you have after you deduct your expenses should then be divided into 4, which will give you your weekly budget. You can even go on to divide that number by 7 if you’d like, determining your daily budget.</p>

                    {/* four */}
                    <h2>4. Save</h2>
                    <p>Always set aside a portion of your income to contribute to your savings account (which you need), even if it’s a small portion that varies month to month. Having money to fall back on in case of emergencies is critical. How much you put away also depends on your goals, and studies vary on how much you should put into savings a month, but the general rule of thumb is 20% of your income.</p>
                    <p>If you can’t swing 20%, just make sure to put something away, aiming for 20% whenever possible.</p>
                    <p>Here’s a great resource on the different types of savings accounts so you can choose which is best for you! When putting your money into savings, it’s best to shop around and weigh out your options, which involves understanding interest rates and how they’re applied to the money in your account. The higher the interest, the more money you’ll earn over time</p>

                    {/* five */}
                    <h2>5. Establish Credit (But Beware of Racking it Up!)</h2>
                    <p>Your credit score is generated, in large part, by your ability to pay your bills and to pay back debt on time. If you don’t have any bills or any debt, it will actually be hard to establish good credit. Therefore, it can be a good idea to establish credit by taking out a line of credit on a card and then paying it back responsibly.</p>
                    <p>A credit line, in essence, is like a loan given to you up front (in the form of a credit card) that you’ll need to pay back, usually at a minimum monthly fee. This is a fantastic overview of how to use a credit card to build credit.</p>
                    <p>If you keep your balance low and make your payment on time every month, your credit score will thrive. But if you let your balance accrue too high, or make late payments, your credit is at great risk. And remember, there’s almost always an interest rate attached to your credit line, meaning that you’re paying back additional money on top of what you actually owe in exchange for the advance on the money. However, you can avoid the interest by making sure you pay off your credit start statement by the due date each month!</p>
                    <p>So remember, having a credit line can be a great thing, but proceed with caution!</p>

                    {/* six */}
                    <h2>6. Protect Yourself</h2>
                    <p>In the digital age, where most of our banking is done online, it’s imperative to protect yourself against bank account hacking and identity theft. This is a to-the-point but highly comprehensive overview of how to prevent identity theft, but here are some of the quickest, easiest ways you can safeguard your money from illegal access to it:</p>
                    <p>Update your passwords frequently and follow all password parameters on sites (the more complicated the password the harder it is to hack your account).</p>
                    <p>NEVER provide personal information like your bank account number or social security number online or over the phone unless you are CERTAIN you are dealing with a trusted source. When in doubt, don’t do it!</p>
                    <p>Be very mindful of what you click on on the internet. Pop-up ads are a huge no-no. Check your credit frequently.</p>
                    <p>Set up fraud alerts or account freezes with credit bureaus (more on that here). Subscribe to an identity theft protection service.</p>

                    {/* seven */}
                    <h2>7. Seek Advice</h2>
                    <p>If you’re feeling overwhelmed about money, seek out the wisdom of those who have more developed financial literacy than you do right now. A trusted family member is a great place to start. Or, you can speak with a financial advisor, whose job it is to help you manage your money safely. This is a great overview of how a financial advisor can help and how you can find the right one for you.</p>

                    {/* eight */}
                    <h2>8. Self Educate</h2>
                    <p>If you’re pretty good with money, but would love some additional insight or inspiration for how to save bigger and invest better, there are plenty of “financial gurus” out there.</p>
                    <p>There are some pretty universally trusted financial experts, including Suze Orman, who has many books and other resources for helping people get out and stay out of debt. Her website also very neatly breaks down money management into various, streamlined categories, so you can find information that pertains to you quickly.</p>

                    {/* nine */}
                    <h2>9. Set Goals</h2>
                    <p>It’s always easier to accomplish something when you have a set goal or destination in mind! Whether you’re saving up for your dream vacation, looking to pay off your student loans entirely, planning to buy a home, etc., you’ll be much more inclined to manage your money better if you know what you plan to do with it. Here’s a resource we like on how to set financial goals, with some common great ideas for financial goals to get you motivated</p>

                    {/* ten */}
                    <h2>10. Do a Daily Check-In</h2>
                    <p>Coming somewhat full-circle to #1 (knowing yourself), it’s a really good idea to stay on top of your finances by taking a peek at them every day. You don’t need more than 5 minutes to check all accounts so that you’re exactly aware of how much money you have and what you’ve spend money on most recently.</p>
                    <p>It’s all too easy to get busy, swipe away on that debit card as you rack up purchases, and then look at your account in shock. If you start your day by taking a quick inventory of your finances, you’re much more likely to spend responsibly!</p>

                    {/* eleven */}
                    <h2>11. Be Smart About Student Loans</h2>
                    <p>Last but not least, our personal favorite tip for improving your financial literacy: managing your student loan debt! One of the best ways to do so is the avoid or reduce student loan debt by winning scholarships (money you don’t have to pay back) for college tuition and other expenses!</p>

                </div>
            </div>
        </div>
    )
}
