import './App.css';
import Blogs from './Components/Blogs';
import Content from './Components/Content';
import Footer from './Components/Footer';
import Home from './Components/Home';
// import Testimonials from './Components/Testimonials';

function App() {
  return (
    <>
      <Home/>
      <Content/>
      <Blogs/>
      {/* <Testimonials/> */}
      <Footer/>
    </>
  );
}

export default App;
