/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const posts = [
    {
      title: 'Know Yourself',
      href: '',
      description:
        'In order to change or improve your financial situation, you’ll have to have a strong sense of what your financial situation looks like.',
      date: 'Mar 16, 2022',
      datetime: '2022-03-16',
    },
    {
      title: 'Budget',
      href: '',
      description: 'If you want to be savvy with money you absolutely must, must, must (did we say must?) make a budget. What exactly IS a budget?',
      date: 'Mar 10, 2022',
      datetime: '2022-03-10',
    },
    {
      title: 'Keys to budgeting',
      href: '',
      description:
        'Fixed expenses are monthly expenses that stay the same every month: rent, your car payment, most bills, your student loan payment, etc. (Side note: you could be eligible for student loan forgiveness—read on!)',
      date: 'Feb 12, 2022',
      datetime: '2022-02-12',
    },
    {
      title: 'Save',
      href: '',
      description:
        'Always set aside a portion of your income to contribute to your savings account (which you need), even if it’s a small portion that varies month to month.',
      date: 'Jan 29, 2022',
      datetime: '2022-01-29',
    },
    {
      title: 'Establish Credit (But Beware of Racking it Up!)',
      href: '',
      description:
        'Your credit score is generated, in large part, by your ability to pay your bills and to pay back debt on time. If you don’t have any bills or any debt, it will actually be hard to establish good credit.',
      date: 'Jan 23, 2022',
      datetime: '2022-01-23',
    },
    {
      title: 'Protect Yourself',
      href: '',
      description:
        'In the digital age, where most of our banking is done online, it’s imperative to protect yourself against bank account hacking and identity theft.',
      date: 'Jan 12, 2022',
      datetime: '2022-01-12',
    },
    {
      title: 'Seek Advice',
      href: '',
      description:
        'If you’re feeling overwhelmed about money, seek out the wisdom of those who have more developed financial literacy than you do right now.',
      date: 'Jan 04, 2022',
      datetime: '2022-01-04',
    },
    {
      title: 'Self Educate',
      href: '',
      description:
        'If you’re pretty good with money, but would love some additional insight or inspiration for how to save bigger and invest better, there are plenty of “financial gurus” out there.',
      date: 'Jan 06, 2022',
      datetime: '2022-01-06',
    },
    {
      title: 'Set Goals',
      href: '',
      description:"It’s always easier to accomplish something when you have a set goal or destination in mind! Whether you’re saving up for your dream vacation, looking to pay off your student loans entirely, planning to buy a home, etc",
      date: 'Jan 06, 2022',
      datetime: '2022-01-06',
    },
    {
      title: 'Do a Daily Check-In',
      href: '',
      description:"Coming somewhat full-circle to #1 (knowing yourself), it’s a really good idea to stay on top of your finances by taking a peek at them every day.",
      date: 'Jan 06, 2022',
      datetime: '2022-01-06',
    },
    {
      title: 'Be Smart About Student Loans',
      href: '',
      description:"Last but not least, our personal favorite tip for improving your financial literacy: managing your student loan debt!",
      date: 'Jan 06, 2022',
      datetime: '2022-01-06',
    },
  ]
  
  export default function Blogs() {
    return (
      <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Financial literacy articles</h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
              <p className="text-xl text-gray-500">Get weekly articles in your inbox on how to grow your business.</p>
              <form className="mt-6 flex flex-col sm:flex-row lg:mt-0 lg:justify-end">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email-address"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none w-full px-4 py-2 border border-gray-300 text-base rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 lg:max-w-xs"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mt-2 flex-shrink-0 w-full flex rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto sm:inline-flex">
                  <button
                    type="button"
                    className="w-full bg-indigo-600 px-4 py-2 border border-transparent rounded-md flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:inline-flex"
                  >
                    Notify me
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {posts.map((post) => (
              <div key={post.title}>
                {/* <p className="text-sm text-gray-500">
                  <time dateTime={post.datetime}>{post.date}</time>
                </p> */}
                <span href="#" className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                  <p className="mt-3 text-base text-gray-500">{post.description}</p>
                </span>
                <div className="mt-3">
                  <span href={post.href} className="text-base font-semibold text-indigo-600 hover:text-indigo-500">
                    Read full story
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  